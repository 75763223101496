var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.click
    ? _c(
        "button",
        {
          class: _vm.data.click,
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.data.click.apply(null, arguments)
            },
          },
        },
        [
          _c("svg-icon", {
            attrs: { name: "ic-arry", width: "16", height: "16" },
          }),
        ],
        1
      )
    : _c(
        "button",
        { class: _vm.data.click },
        [
          _c("svg-icon", {
            attrs: { name: "ic-arry", width: "16", height: "16" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }